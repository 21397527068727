<template>
    <div class="video">
        <Header></Header>
        <div class="video-content">
             <div class="content-item">
                    <div class="item-list" v-for="item in itemList" :key="item.id"
                     @click="goVideoDetails(item.id)">
                        <span>{{item.title}}</span>
                        <span>时间：{{item.createtime}}</span>
                        <video :src="BaseUrl + item.attrfile" controls></video>
                    </div>
            </div>  
            <div class="downPage">
                  <el-pagination
                      background
                      prev-text="上一页"
                      next-text="下一页"
                      @prev-click="pagePrev"
                      @next-click="pageNext"
                      @current-change="pageChange"
                      page-size="9"
                      layout=" prev, pager, next">
                </el-pagination>
            </div> 
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../../../components/mobile/Header.vue'
import Footer from '../../../components/mobile/Footer.vue'
export default {
    name:'Video',
    data() {
        return {
            page:1,
            pageSize:8,
            totalPage:'',
            itemList:[]
        }
    },
    components:{
        Header,
        Footer
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
            this.$http({
                url:'/yingshi/list',
                params:{
                    page:this.page,
                    pagesize:this.pageSize
                }
            }).then(res=>{
                console.log(res)
                this.totalPage =res.data.total
                this.itemList = res.data.list
            })
        },
        goVideoDetails(id){
            this.$router.push(`/m_videodetails/${id}`)
        },
        // 上一页
        pagePrev(){
            this.page--
            this.getData(this.page)
        },
        // 下一页
        pageNext(){
             console.log('下一页')
             this.page++
             this.getData(this.page)
        },
        // 页面改变
        pageChange(e){
            console.log(e)
            this.page = e
            this.getData(this.page)
        },
    }
}
</script>

<style lang='less' scoped>
.video{
    .video-content{
        width: 690px;
        margin: 40px auto 50px;
        .content-item{
             .item-list{
                margin-bottom: 28px;
                padding-bottom: 30px;
                border-bottom: 1px solid #E5E5E5;
                >span:nth-child(1),
                >span:nth-child(2){
                    display: block;
                     font-size: 28px;
                     color: #333333;
                    margin-bottom: 23px;
                }
                 >span:nth-child(2){
                     font-size: 20px;
                }
                >video:nth-child(3){
                       width: 690px;
                       height: 317px;
                }
            }
        }
        .downPage{
            width: 650px!important;
            margin: 50px auto 0;
            text-align: center;
        }
        /deep/.el-pagination{
            width: 650px!important;
        }
    }
}
</style>